import React, {useRef} from 'react';
import Drawer from '@mui/material/Drawer'
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Button from '@mui/material/Button';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText'
import orderHelpers from '../../helpers/orders';
import DetailsTable from './DetailsTable';

function HoldOrderDetails(props) { 

    const order = props.order || {};


    const acceptOrder = async() => {
        if(!window.confirm(`Accept order ${order.internal_po_number}`)) return;
        props.setLoading(true);
        await orderHelpers.acknowledgeOrder(props.company, JSON.stringify({internal_po_number: order.internal_po_number, vendor_name: order.vendor_name, cancel: false}), props.token);
        props.setLoading(false);
    }

    const cancelOrder = async() => {
        if(!window.confirm(`Cancel order ${order.internal_po_number}?`)) return;
        props.setLoading(true);
        await orderHelpers.acknowledgeOrder(props.company, JSON.stringify({internal_po_number: order.internal_po_number, vendor_name: order.vendor_name, cancel: true}), props.token);
        props.setLoading(false);
    }


    return (
        <Drawer open={Object.keys(order).length > 0} onClose={props.setOpenDetails} anchor="bottom">
            <Box style={{height: "600px"}}  display="flex" sx={{m:"auto", mb:"80px", pt:"20px", width:"80%"}}>
                <Box style={{width:"40%"}}>
                    <List dense>
                        <ListItem>
                            <ListItemText primary="Order Number" primaryTypographyProps={{variant:"h4"}} secondaryTypographyProps={{variant:"h5"}} secondary={order.internal_po_number} />
                        </ListItem>
                        <ListItem>
                            <ListItemText primary="Order Date" secondary={order.order_date ? order.order_date.substring(0, 10) : "N/A"}/>
                        </ListItem>
                        <ListItem>
                            <ListItemText primary="Vendor" secondary={order.vendor_name} />
                        </ListItem>
                        <ListItem>
                            <ListItemText primary="Acumatica Order" secondary={order.acu_order_num || "N/A"}/>
                        </ListItem>
                        <ListItem>
                            <ListItemText primary="Total Price" secondary={`$${order.price}`}/>
                        </ListItem>
                        <ListItem>
                            <ListItemText primary="Piece Count" secondary={order.pieces} />
                        </ListItem>
                    </List>
                </Box>
                <Divider sx={{ml:"10px", mr:"10px"}} orientation="vertical" flexItem />
                <Box display="flex" flexDirection="column" style={{width:"35%"}}>
                    <h3>Customer Info</h3>
                    <Box display="flex">
                        <List dense>
                            <ListItem>
                                <ListItemText  primary="Customer Name" secondary={order.name}/>
                            </ListItem>
                            <ListItem>
                                <ListItemText primary="Street Address" secondary={order.address1}/>
                            </ListItem>
                            <ListItem>
                                <ListItemText primary="Street Address 2" secondary={order.address2 || "N/A"}/>
                            </ListItem>
                        </List>
                        <List dense>
                            <ListItem>
                                <ListItemText primary="City" secondary={order.city} />
                            </ListItem>
                            <ListItem>
                                <ListItemText primary="State" secondary={order.state} />
                            </ListItem>
                            <ListItem>
                                <ListItemText primary="Zip Code" secondary={order.zip_code} />
                            </ListItem>
                        </List>
                    </Box>
                </Box>
                <Divider sx={{ml:"10px", mr:"10px"}} orientation="vertical" flexItem />
                {order.items ? 
                    <Box style={{width:"100%"}}>
                        <DetailsTable {...props} items={order.items} />
                    </Box>
                : null
                }   
                <Box display="flex" flexDirection="column">
                    <Button color="success" onClick={()=>acceptOrder()} variant="contained" style={{margin:"20px"}}>Approve</Button>
                    <Button color="error" onClick={()=>cancelOrder()} variant="contained" style={{margin:"20px"}}>Reject</Button>
                </Box>

            </Box>
        </Drawer>
    )

}

export default HoldOrderDetails;


