import React from 'react';
import Box from '@mui/material/Box';
import Tooltip from '@mui/material/Tooltip';
import { DataGrid } from '@mui/x-data-grid';
import {lighten } from '@mui/material/styles';

function HoldTable(props) {

    
    const columns = [
        {
            field: 'id',
            headerName: 'ID',
            minWidth: 100,
            flex:1, 
            editable: false
        }, 
        {
            field: 'vendor_name',
            headerName: 'Vendor',
            minWidth: 250,
            flex:1, 
            editable: false
        }, 
        {
            field: 'internal_po_number',
            headerName: 'PO',
            renderCell: (params) => {
                return(
                    <Tooltip title={params.row.internal_po_number}>
                        <Box style={{whiteSpace:"nowrap",overflow:"hidden",textOverflow:"ellipsis"}}>{params.row.internal_po_number}</Box>
                    </Tooltip>
                )
            },
            minWidth: 400,
            flex:1, 
            editable: false
        }, 
        {
            field: 'order_date',
            headerName: 'Date',
            minWidth: 200,
            flex:1, 
            editable: false
        }, 
        {
            field: 'pieces',
            headerName: 'Pieces',
            minWidth: 100,
            flex:1, 
            editable: false
        }, 
        {
            field: 'total_price',
            headerName: 'Price',
            minWidth: 100,
            flex:1, 
            editable: false
        }, 
    ];



    return(
        <Box className={props.isMobile ? "mobile-box" : "desktop-box"} sx = {{display: 'flex', flexDirection: 'row'}}>
            <Box className={props.isMobile ? "mobile-table-box" : "desktop-table-box"} style={{ height: 1000, marginTop: '50px'}} sx={{ 
                        '& .failed': {
                            bgcolor: (theme) => lighten(theme.palette.error.light, 0.5)
                        }
                        }}>
                <DataGrid
                className={props.isMobile ? "mobile-table" : "desktop-table"} 
                rows={props.orders}
                columns={columns}
                rowsPerPageOption = {[25, 50, 100]}
                onSelectionModelChange={(newSelectionModel) => {
                    props.handleSelect(newSelectionModel);
                }}
                selectionModel={props.selected}
                sortModel = {[{field: 'id', sort: 'desc'}]}
                />
            </Box>            
        </Box>
    )
}

export default HoldTable