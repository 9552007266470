import Box from '@mui/material/Box';

import React, { useEffect, useState } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import TextField from '@mui/material/TextField';


function SplitTable(props) {

    const handleChange = async(e, wh, item) => {
        let val = Number(e.target.value);
        let oth_wh = "pa";
        if(wh === "pa") oth_wh = "ky";
        if(val + (item["fulfill_" + oth_wh] || 0) > item.quantity) {
            e.target.value = item["fulfill_" + wh];
            return;
        }
        let temp = props.order;
        let index = temp.items.findIndex(obj => obj.internal_sku === item.internal_sku);
        temp.items[index]["fulfill_" + wh] = val;
        props.setOrder(temp);

    }

    return(
        <Box className={props.isMobile ? "mobile-table-box" : "desktop-table-box"} style={{width:"100%"}}>
            <TableContainer className={props.isMobile ? "mobile-table-box" : "desktop-table-box"}>
                <Table className={props.isMobile ? "mobile-table" : "desktop-table"}  sx={{zIndex:0}}>
                    <TableHead>
                        <TableRow>
                            <TableCell align="center" style={{fontWeight: "bold"}}>SKU</TableCell>
                            <TableCell align="center" style={{fontWeight: "bold"}}>Order Quantity</TableCell>
                            <TableCell align="center">KY Stock</TableCell>
                            <TableCell align="center">PA Stock</TableCell>
                            <TableCell align="center">Fulfill KY</TableCell>
                            <TableCell align="center">Fulfill PA</TableCell>
                        </TableRow>
                    </TableHead>
                        <TableBody>
                            {props.order.items.filter(obj => obj.decision === "Split" || obj.decision === "Partial").map((row, index) => {
                                return(
                                    <TableRow key={index} tabIndex={-1}>
                                        <TableCell align="center">{row.internal_sku}</TableCell>
                                        <TableCell align="center">{row.quantity}</TableCell>
                                        <TableCell align="center">{row.kentucky}</TableCell>
                                        <TableCell align="center">{row.pennsylvania}</TableCell>
                                        <TableCell align="center"><TextField onChange={(e)=>handleChange(e, "ky", row, index)} defaultValue={row.fulfill_ky || 0} type="number" InputProps={{inputProps: {max:row.kentucky, min:0}}} /></TableCell>
                                        <TableCell align="center"><TextField onChange={(e)=>handleChange(e, "pa", row, index)} defaultValue={row.fulfill_pa || 0} type="number" InputProps={{inputProps: {max:row.pennsylvania, min:0}}} /></TableCell>
                                    </TableRow> 
                                )
                            })}
                        </TableBody>
                </Table>
                </TableContainer>
        </Box>   
    )


}

export default SplitTable;