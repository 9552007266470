import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import ProcessingDetails from './ProcessingDetails';
import SplitTable from './SplitTable';
import ReviewPanel from './ReviewPanel';
import Tabs from '@mui/material/Tabs'
import Tab from '@mui/material/Tab';

function ProcessingPanel(props) {

    const [page, setPage] = useState(0);

    const decisions = [
        "Kentucky",
        "Pennsylvania",
        "Split",
        "Partial",
        "Cancel"
    ]
    // const [inv, setInv] = useState({});


    const overrideDecision = async(rows, decision) => {
        let temp = props.order;
        let ids = rows.map(obj => obj.id);
        console.log(`overriding ${rows}, ${decision}`);
        for(let i = 0; i < temp.items.length; i++) {
            if(ids.includes(temp.items[i].id)) {
                temp.items[i].decision = decision;
            }
        }
        props.setOrder(temp);
        if(temp.items.every(obj => obj.decision === "Cancel")) props.setAllCancel(true);
        else props.setAllCancel(false);
        if(temp.items.every(obj => obj.decision !== "Split" && obj.decision !== "Partial")) props.setNeedsSplit(false);
        else props.setNeedsSplit(true);
    }

    const handlePageChange = async(e, value) => {
        console.log(value);
        setPage(value);
    }

    return(
        <Box display="flex" flexDirection="column" style={{width:"100%"}}>
            <Tabs value={page} onChange={handlePageChange}>
                <Tab align="center" label={"Decisions"} />
                <Tab align="center" label={"Split"} disabled={props.all_cancel || !props.needs_split} />
                <Tab align="center" label={"Review"} disabled={props.all_cancel} />
            </Tabs>
            {page === 0 ?
                <Box style={{width:"100%"}}>
                    <ProcessingDetails {...props} decisions={decisions} overrideDecision={overrideDecision} items={props.order.items} />
                </Box>
            : null}
            {page === 1 && !props.all_cancel ?
                <Box style={{width:"100%"}}>
                    <SplitTable {...props} />
                </Box>  
            : null}
            {page === 2 && !props.all_cancel ?
                <Box style={{width:"100%"}}>
                    <ReviewPanel {...props} />
                </Box>
            : null}


                {/* {
                    !props.all_cancel ?
                    <Button size="large" style={{height:"fit-content", margin:"auto", marginLeft:"30px"}} variant="contained" color="success">Continue <ArrowForwardIcon /> </Button>
                    :
                    null
                } */}
        </Box>
    )
}

export default ProcessingPanel;