import React, { useEffect, useState } from 'react';
import Drawer from '@mui/material/Drawer'
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText'
import CircularProgress from '@mui/material/CircularProgress';
import orderHelpers from '../../helpers/orders';
import DetailsTable from './DetailsTable';

function BulkOrderDetails(props) {
    const [order, setOrder] = useState(null);

    
    useEffect(() => {
        const getOrder = async() => {
            let body = {internal_po_number: props.selected.internal_po_number, vendor_name: props.selected.vendor_name}
            let res = await orderHelpers.getBulkOrders(props.company + "_bulk", body, props.token);
            if(res.length === 0) {
                setOrder(null);
                return;
            }
            let out = res[0];
            out.items = [];
            let skus = [...new Set(res.map(obj => obj.internal_sku))];
            let i = 1;
            for(let sku of skus) {
                let items = res.filter(obj => obj.internal_sku === sku);
                let count = items.reduce((a, b) => a + b.quantity, 0);
                let item = items[0];
                out.items.push({
                    id: i,
                    internal_sku: item.internal_sku,
                    external_sku: item.external_sku,
                    external_price: item.external_price,
                    internal_price: item.internal_price,
                    quantity: count
                });
                i++;
            }
            setOrder(out);
            console.log(order);
        }
        getOrder();
    }, [])
    
    

    return(
        <Drawer open={props.openDetails} onClose={() => props.setOpenDetails(false)} anchor="bottom">
            {order ? 
            <Box style={{height: "600px", width:"75%"}}  display="flex" sx={{m:"auto", mb:"80px", pt:"20px"}}>
                <List dense>
                    <ListItem>
                        <ListItemText primary="Order Number" primaryTypographyProps={{variant:"h4"}} secondaryTypographyProps={{variant:"h5"}} secondary={order.internal_po_number} />
                    </ListItem>
                    <ListItem>
                        <ListItemText primary="Order Date" secondary={order.order_date ? order.order_date.substring(0, 10) : "N/A"}/>
                    </ListItem>
                    <ListItem>
                        <ListItemText primary="Vendor" secondary={order.vendor_name} />
                    </ListItem>
                    <ListItem>
                        <ListItemText primary="Acumatica Order" secondary={order.acu_order_num || "N/A"}/>
                    </ListItem>
                </List>
                <Divider sx={{ml:"10px", mr:"10px"}} orientation="vertical" flexItem />
                <Box style={{width:"100%"}}>
                    <DetailsTable {...props} items={order.items} />
                </Box>
                
            </Box> :
            <Box display="flex" style={{height:"100px"}}>
                <CircularProgress style={{margin:"auto"}} />
            </Box>}
        </Drawer>
    )
}

export default BulkOrderDetails;