import axios from 'axios';
import 'dotenv/config';
import getJWT from './token';
import zlib from 'zlib';

let api = `https://api.${process.env.REACT_APP_BASE_URL === "commerce-ims.com" ? "" : "dev."}furhaven-production.com`

const warehouseHelpers = {

    getWorkers: async function(company_id, token){
        const userToken = await getJWT(token);
        const url = `${api}/v1/operations/getAllWorkers`;
        try {
            let res = await axios.get(url, { headers: { company_id:company_id[0].toUpperCase() + company_id.substring(1), Authorization: `Bearer ${userToken}` } })
            let out = res.data.workers.sort((a, b) => {
                if(a.warehouse !== b.warehouse) {
                    if(a.warehouse === "KY") return -1;
                    return 1;
                }
                return Number(a.worker_id) - Number(b.worker_id);
            })
            return out;
        } catch (err) {
            console.log(err);
            throw err;
        }
    },

    getTimes: async function(company_id, token, params){
        const userToken = await getJWT(token);
        const url = `${api}/v1/operations/employee/times`
        console.log(params)
        try {
            let res = await axios.get(url, { headers: { company_id:company_id[0].toUpperCase() + company_id.substring(1), Authorization: `Bearer ${userToken}` }, params})
            console.log(res)
            let out = res.data;
            return out.times;
        } catch (err) {
            console.log(err);
            throw err;
        }
    },

    getEfficiencyTime: async function(company_id, token){
        const userToken = await getJWT(token);
        const url = `${api}/v1/operations/efficiencyTimes`
        try {
            let res = await axios.get(url, { headers: { company_id:company_id[0].toUpperCase() + company_id.substring(1), Authorization: `Bearer ${userToken}` }})
            console.log(res)
            let out = res.data;
            return out.times;
        } catch (err) {
            console.log(err);
            throw err;
        }
    },

    getEfficiencyBuild: async function(company_id, token){
        const userToken = await getJWT(token);
        const url = `${api}/v1/operations/efficiencyBuilds`
        try {
            let res = await axios.get(url, { headers: { company_id:company_id[0].toUpperCase() + company_id.substring(1), Authorization: `Bearer ${userToken}` }})
            console.log(res)
            let out = res.data;
            return out.times;
        } catch (err) {
            console.log(err);
            throw err;
        }
    },

    getEfficiencyExpected: async function(company_id, token){
        const userToken = await getJWT(token);
        const url = `${api}/v1/operations/efficiencyExpected`
        try {
            let res = await axios.get(url, { headers: { company_id:company_id[0].toUpperCase() + company_id.substring(1), Authorization: `Bearer ${userToken}` }})
            console.log(res)
            let out = res.data;
            return out.times;
        } catch (err) {
            console.log(err);
            throw err;
        }
    }
}

export default warehouseHelpers;