import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import EmployeeQueryForm from './EmployeeQueryForm';
import warehouseHelpers from '../../helpers/warehouse';
import EmployeeTimeTable from './EmployeeTimeTable';

function EmployeeTimes(props) {

    const [times, setTimes] = useState([])
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [ids, setIds] = useState([]);
    const [selectedId, setSelectedId] = useState([]);
    const [warehouse, setWarehouse] = useState("");


    useEffect(() => {
        const getIds = async() => {
            props.setLoading(true);
            let workers = await warehouseHelpers.getWorkers(props.company, props.token);
            setIds(workers)
            props.setLoading(false);
        }
        getIds()
    }, [])

    const setId = async(e) => {
        setSelectedId(e.target.value);
    }

    const clear = async() => {
        setStartDate(null);
        setEndDate(null);
        setSelectedId([]);
        setWarehouse("");
    }

    

    const getTimes = async() => {
        props.setLoading(true);
        let request = {};
        if(startDate) {
            request.startDate = startDate;
        }
        if(endDate) {
            request.endDate = endDate;
        }
        if(selectedId.length > 0) {
            request.id = selectedId;
        }
        if(warehouse !== "") {
            request.warehouse = warehouse;
        }
        let times_res = await warehouseHelpers.getTimes(props.company, props.token, JSON.stringify(request));
        setTimes(times_res)
        props.setLoading(false);
    }

    return(
        <Box display="flex" flexDirection="column" className={props.isMobile ? "mobile-top" : "desktop-top"}>
            <Box className={props.isMobile ? "mobile-box" : "desktop-box"}>
                <h1>Employee Times</h1> 
                <EmployeeQueryForm {...props} search={getTimes} startDate={startDate} endDate={endDate} setStartDate={setStartDate} setEndDate={setEndDate} ids={ids} id={selectedId} warehouse={warehouse} setWarehouse={setWarehouse} setId={setId} clear={clear} />
                {times.length > 0  ? <EmployeeTimeTable {...props} times={times} /> : null}
            </Box>
        </Box>
    )
}

export default EmployeeTimes;