import React, { Component } from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { DataGrid } from '@mui/x-data-grid';

const columns = [
    {
        field: 'internal_po_number',
        headerName: 'PO Number',
        flex: 1,
        minWidth: 75,
        editable: false
    }, 
    {
        field: 'vendor_name',
        headerName: 'Vendor',
        flex: 1, 
        minWidth: 150,
        editable: false
    }, 
    {
        field: 'order_date',
        headerName: 'Date',
        flex: 1,
        minWidth: 100,
        valueGetter: (params) => {
            if(!params.row.order_date) return "";
            return params.row.order_date.substring(0, 10)
        }, 
        editable: false
    },
    {
        field:'order_status',
        headerName: 'Status',
        flex: 1,
        minWidth: 150,
        valueGetter: (params) => {
            let name = params.row.order_status;
            let back = name.substring(name.indexOf("_") + 1);
            return (name[0].toUpperCase() + name.substring(1, name.indexOf("_")) + " " + back[0].toUpperCase() + back.substring(1));
        },
        editable: false
    }
]

class OrderSearchTable extends Component{

    componentDidMount(){
        // this.props.getStaticUpdates();
    }

    exportOrders = async() => {
        let data = [
            ["Vendor", "Date", "PO", "SKU", "QTY", "ZIP", "ACU"].join(",")
        ];
        for(let order of this.props.orders) {
            for(let item of order.items) {
                data.push(
                    [order.vendor_name, order.order_date, order.internal_po_number, item.internal_sku, 1, order.zip_code, order.acu_order_num].join(",")
                )
            }
        }
        let csv = data.join("\n");
        let uri = encodeURI("data:text/csv;charset=utf-8," + csv);
        let a = document.createElement("a");
        a.setAttribute("href", uri);
        a.setAttribute("download", "OrderSearchExport.csv");
        document.body.appendChild(a);
        a.click();
    }

    render(){
        return(
            <Box className={this.props.isMobile ? "mobile-box" : "desktop-box"}>
                {this.props.orders.length > 0 ? <Button style={{marginTop:"10px"}} variant="contained" className={`${this.props.company} ${this.props.warehouse} secondary`}onClick={this.exportOrders}>Export</Button> : null}
                <Box className={this.props.isMobile ? "mobile-box" : "desktop-box"}>
                        <Box className={this.props.isMobile ? "mobile-table-box" : "desktop-table-box"}>
                            <DataGrid
                                className={this.props.isMobile ? "mobile-table" : "desktop-table"}
                                sx={{height:"1000px"}}
                                rows={this.props.orders}
                                columns={columns}
                                rowsPerPageOption = {[25, 50, 100]}
                                checkboxSelection = {false}
                                onRowClick = {this.props.setOpenDetails}
                            />
                        </Box>
                </Box>
            </Box>
        )
    }
}

export default OrderSearchTable;