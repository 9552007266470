import {React, useState, useEffect} from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import DashboardCard from './DashboardCard';
import userHelpers from '../../helpers/users';
import ExportPopup from './ExportPopup';
import orderHelpers from '../../helpers/orders';


function Dashboard(props) {
    const [data, setData] = useState({});
    const [keys, setKeys] = useState([]);
    const [refresh, setRefresh] = useState(false);
    const [open, setOpen] = useState(false);
    const [startDate, setStartDate] = useState(new Date());
    const [endDate, setEndDate] = useState(null);
    const [wh, setWh] = useState(props.warehouse === "all" ? "Kentucky" : props.warehouse)

    useEffect(() => {
        const getData = async() => {
            try {
                props.setLoading(true);
                let res = await userHelpers.getDashboardData(props.company, props.token);
                let out = {};
                let headers = [];
                for(let type in res) {
                    out[type] = [];
                    let temp = res[type];
                    for(let obj of temp) {
                        for(let key in obj) {
                            for(let index in obj[key]) {
                                out[type].push({
                                    key: key, value: obj[key][index]
                                })
                            }
                        }
                        headers.push(type);
                    }
                }
                setData(out);
                setKeys(headers);
            }
            catch(err) {
                setData({});
                setKeys([]);
            }
            props.setLoading(false);
        }

        getData();
    }, [refresh, props.company, props.warehouse])

    const handleClose = async() => {
        setOpen(false);
        setStartDate(null);
        setEndDate(null);
    }

    const exportOrders = async(start, end, warehouse) => {
        props.setLoading(true);
        let s_d = new Date(start).toISOString().substring(0, 10);
        let e_d = null;
        if(end) {
            e_d = new Date(end).toISOString().substring(0, 10);
        }
        let payload = {start_date:s_d, end_date:e_d, warehouse: warehouse}
        let orders = await orderHelpers.getOrders(props.company, payload, props.token);
        if(orders.length > 0) {
            downloadExport(orders, warehouse, s_d);
        }
        props.setLoading(false);
    }

    const downloadExport = async(orders, wh, start) => {
        let headers = Object.keys(orders[0]);
        let out = [headers];
        for(let obj_row of orders) {
            let row = [];
            for(let header of headers) {
                row.push(String(obj_row[header]).replace(/,/g, "-"))
            }
            out.push(row.join(","));
        }
        let csv = out.join("\n");
        let blob = new Blob([csv], {type: "text/csv"})
        let uri = window.URL.createObjectURL(blob);
        console.log("URI", uri)
        let a = document.createElement("a");
        a.setAttribute("href", uri);
        a.setAttribute("download", wh + "_order_export_" + start + ".csv");
        document.body.appendChild(a);
        a.click();
    }

    const exportUnprocessed = async() => {
        props.setLoading(true);
        console.log("Getting unprocessed");
        let orders = await orderHelpers.getOrders(props.company, {order_valid: true, null_pullsheet: true}, props.token);
        console.log(orders)
        await downloadExport(orders, "unprocessed", new Date())
        props.setLoading(false);
    }

    const exportToday = async() => {
        props.setLoading(true);
        let req = {warehouse: props.warehouse};
        // if(props.warehouse.toLowerCase() !== "all") {
        //     req.warehouse = props.warehouse
        // }
        let now = new Date();
        // now.setDate(now.getDate() - 1);
        // req.start_date = now.toISOString().substring(0, 10);
        // req.time = "12:00:00";
        // now.setDate(now.getDate() + 1);
        // req.end_date = now.toISOString().substring(0, 10);
        let orders = await orderHelpers.getWarehouseReport(props.company, req, props.token);
        console.log(orders);
        let warehouses = {}
        for(let order of orders) {
            if(!(order.warehouse in warehouses)) {
                warehouses[order.warehouse] = {};
            }
            if(!(order.vendor_name in warehouses[order.warehouse])) {
                warehouses[order.warehouse][order.vendor_name] = 0;
            }
            warehouses[order.warehouse][order.vendor_name] += order.count;
        }
        let out = [];
        for(let warehouse in warehouses) {
            let vendors = warehouses[warehouse];
            for(let vendor in vendors) {
                out.push({
                    vendor: vendor,
                    warehouse: warehouse,
                    pieces: vendors[vendor]
                })
            }

        }
        if(out.length > 0) {
            downloadExport(out, props.warehouse, now);
        }
        props.setLoading(false);
    }

        return(
            <Box display="flex" flexDirection="column" className={props.isMobile ? "mobile-top" : "desktop-top"} style={{width:"100%"}}>
                <Box className={props.isMobile ? "mobile-box" : "desktop-box"}>
                    <ExportPopup {...props} wh={wh} setWh={setWh} exportOrders={exportOrders} open={open} handleClose={handleClose} setOpen={setOpen} setStartDate={setStartDate} startDate={startDate} endDate={endDate} setEndDate={setEndDate} />
                    <h1>Dashboard</h1>
                    <Button variant="contained" className={`${props.warehouse} ${props.company} primary`} disabled={props.isLoading} onClick={()=>setOpen(true)} style={{marginRight:"20px"}}>Export Orders</Button>
                    <Button variant="contained" className={`${props.warehouse} ${props.company} tertiary`} disabled={props.isLoading} onClick={()=>exportToday()} style={{marginRight:"20px"}}>Export Today's Counts</Button>
                    <Button variant="contained" className={`${props.warehouse} ${props.company} secondary`} disabled={props.isLoading} onClick={()=>exportUnprocessed()} style={{marginRight:"20px"}}>Export Unprocessed</Button>
                    <Button variant="contained" color="info" disabled={props.isLoading} onClick={()=>setRefresh(!refresh)}>Refresh</Button>
                    
                    {data  ? 
                        keys.map((obj) => {
                            if(!(obj in data)) return;
                            return(
                                <Box className={props.isMobile ? "mobile-box" : "desktop-box"} display="flex" flexDirection="column">
                                    <h2>{obj.toUpperCase()}</h2>
                                    <Box display="flex" flexDirection="row">
                                    {data[obj].map((inner) => {
                                        return(<DashboardCard data={inner} />)
                                    })}
                                    </Box>

                                </Box>
                            )
                        })

                    : <Box className={props.isMobile ? "mobile-box" : "desktop-box"} display="flex" flexDirection="row" />}
                </Box>
            </Box>
        )
    
}

export default Dashboard;