import { DataGrid } from '@mui/x-data-grid';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import {lighten } from '@mui/material/styles';
import React, { useEffect, useState } from 'react';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';



function ProcessingDetails(props) {

    const [override, setOverride] = useState("Kentucky");
    const [selected_rows, setSelectedRows] = useState([]);
    const [full_selected_rows, setFullSelectedRows] = useState([]);

    const columns = [ 
        {
            field: 'id',
            headerName: "ID",
            flex: 1,
            editable: false,
            minWidth: 100
        },
        {
            field: 'internal_sku',
            headerName: 'Internal SKU',
            flex: 1,
            editable: false,
            minWidth: 200
        }, 
        {
            field: 'internal_price',
            headerName: 'Internal Price',
            flex: 1,
            editable: false,
            minWidth: 150
        },
        {
            field: 'external_price',
            headerName: 'External Price',
            flex: 1,
            editable: false,
            minWidth: 100
        },
        {
            headerName: 'Order QTY',
            field: "quantity",
            sortable: false,
            editable: false,
            minWidth: 100,
        },
        {
            headerName: 'KY',
            field: "kentucky",
            sortable: false,
            editable: false,
            minWidth: 100,
        },
        {
            headerName: 'PA',
            field: "pennsylvania",
            sortable: false,
            editable: false,
            minWidth: 100,
        },
        {
            headerName: 'Decision',
            field: "decision",
            sortable: false,
            editable: false,
            minWidth: 200,
        }   
    
    ]

    const saveOverride = async() => {
        await props.overrideDecision(full_selected_rows, override);
        setOverride("Kentucky");
        setSelectedRows([]);
        setFullSelectedRows([]);
    }

    return(
        <Box className={props.isMobile ? "mobile-table-box" : "desktop-table-box"} style={{width:"100%"}}>
            {selected_rows.length > 0 ? 
            <Box display="flex" style={{marginBottom:"10px"}}>
                <FormControl>
                    <InputLabel id="overrideLabel">Decision</InputLabel>
                    <Select style={{width:"200px"}} labelId="overrideLabel" value={override} label="Decision" onChange={(e) => setOverride(e.target.value)}>
                        {props.decisions.map(obj => {
                            return (
                                <MenuItem value={obj}>{obj}</MenuItem>
                            )
                        })}
                    </Select>
                </FormControl>
                <Button onClick={() => saveOverride()}>Save</Button>
            </Box>


            : null}
            <DataGrid
                style={{width:"100%"}}
                rows={props.items}
                className={props.isMobile ? "mobile-table" : "desktop-table"}
                columns={columns}
                rowsPerPageOption = {[25, 50, 100]}
                checkboxSelection = {true}
                selectableRowsHeader = {true}
                selectionModel = {selected_rows}
                onSelectionModelChange = {(ids) => {
                    let filtered = props.items.filter((row) => {
                        return ids.includes(row.id) || ids.includes(row.id.toString());
                    })
                    setFullSelectedRows(filtered);
                    setSelectedRows(ids);
                }}
                getRowClassName={(params) => params.row.class}
                sortModel = {[{field:'id', sort: 'desc'}]}
            />
        </Box>   
    )


}

export default ProcessingDetails;