import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import bulkHelpers from '../../helpers/bulk';
import BulkTable from './BulkTable';
import BulkOrderDetails from './BulkOrderDetails';

function NewOrdersTable(props) {
    const [rows, setRows] = useState([]);
    const [refresh, setRefresh] = useState(false);
    // const [selectedRows, setSelectedRows] = useState([]);
    const [openDetails, setOpenDetails] = useState(false);

    const company = props.company + "_bulk";
    const warehouse = props.warehouse;
    const token = props.token;
    const setLoading = props.setLoading;

    useEffect(() => {
        const getOrders = async() => {
            setLoading(true)
            let body = {
                status: props.status
            };
            if(props.warehouse.toLowerCase() !== "all") body.warehouse = props.warehouse;
            console.log(body)
            let orders = await bulkHelpers.getBulkOrdersTable(company, body, token);
            if(!orders || orders.length === 0) {
                console.log("No Orders Found.");
                setRows([]);
                setLoading(false);
                return;
            }
            // console.log(orders)
            // let pos = [...new Set(orders.map(obj => obj.internal_po_number))];
            // let po_out = {};
            // for(let po of pos) {
            //     let filtered = orders.filter(obj => obj.internal_po_number === po);
            //     po_out[po] = {rows: filtered, total: filtered.reduce((a, b) => a + b.quantity, 0)};
            // }
            // let out = [];
            // let i = 0;
            // for(let po in po_out) {
            //     let po_obj = po_out[po];
            //     out.push({
            //         id: i,
            //         vendor_name: po_obj.rows[0].vendor_name,
            //         internal_po_number: po,
            //         order_date: po_obj.rows[0].order_date.replace(".000Z", ""),
            //         pieces: po_obj.total,
            //         quantity: po_obj.quantity,
            //         warehouse: po_obj.warehouse,
            //         built: po_obj.built
            //         // order_status: po_obj.rows[0].order_status
            //     })
            //     i++;
            // }
            setRows(orders);
            console.log(orders)
            setLoading(false);
        }
        getOrders();
        return () => {
            setRows([])
        }
    }, [refresh, company, warehouse, token, setLoading])


    return(
        <Box display="flex" flexDirection="column">

        <Box> 
            {props.selectedRows.length === 1 ? 
                <Box display="flex" style={{marginTop:"50px"}}>
                    {props.buttons.map(obj => {
                        return(<Button className={`${props.company} ${props.warehouse} ${obj.type}`} sx={{mr:"15px"}} variant="contained" onClick={obj.function}>{obj.text}</Button>)
                    })}
                    <Button onClick={()=>setOpenDetails(true)} className={`${props.company} ${props.warehouse}`} color="info" variant="contained">View Details</Button>
                </Box>
            : null}
            <Box display="flex">
                <Button variant='contained' onClick={() => setRefresh(!refresh)} color="success" sx={{ml: props.isMobile ? '20px' : '800px', mt: '20px', maxHeight: '45px'}}>
                    Refresh
                </Button>
            </Box>
            <BulkTable {...props} rows={rows} />
            {openDetails ? <BulkOrderDetails openDetails={openDetails} setOpenDetails={setOpenDetails} selected={props.selectedRows[0]} {...props} /> : null}
        </Box>
    </Box>
    )
    
}

export default NewOrdersTable;