import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Input from '@mui/material/Input';
import DatePicker from '@mui/lab/DatePicker';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import TextField from '@mui/material/TextField';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';

function EmployeeQueryForm(props) {


    return(
        <Box className={props.isMobile ? "mobile-box" : "desktop-box"} display="flex" flexDirection="column">
            <Box className={props.isMobile ? "mobile-box" : "desktop-box"} display="flex" flexDirection="column">
                <Box display="flex">
                    <FormControl>
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                            <DatePicker
                                label="Start Date"
                                name='orderStartDate'
                                onChange={(e) => props.setStartDate(e)}
                                value={props.startDate ? props.startDate : null}
                                renderInput={(params) => <TextField {...params} />}
                                clearable={true}
                            />
                        </LocalizationProvider>
                    </FormControl>
                    <FormControl sx={{ml:"20px"}}>
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                            <DatePicker
                                label="End Date"
                                name='orderEndDate'
                                onChange={(e) => props.setEndDate(e)}
                                value={props.endDate ? props.endDate : null}
                                renderInput={(params) => <TextField {...params} />}
                                clearable={true}
                            />
                        </LocalizationProvider>
                    </FormControl>
                </Box>
                <Box className={props.isMobile ? "mobile-box" : "desktop-box"} display="flex" sx={{mt:"20px"}}>
                    <FormControl sx={{minWidth:"300px"}}>

                        <InputLabel id="employee_label">Employee</InputLabel>
                        <Select
                            value={props.id}
                            name='employee'   
                            label="Employee"        
                            onChange={props.setId}
                            multiple
                            defaultValue={""}
                        >
                        {props.ids ? props.ids.map((obj,index) => {
                            return(<MenuItem key={index} value={obj.worker_id}>{obj.worker_id} | {obj.worker_name} ({obj.warehouse})</MenuItem>)
                        }) : null}
                        </Select>
                    </FormControl>
                    <FormControl sx={{minWidth:"300px", ml:"20px"}}>
                        <InputLabel id="warehouse_label">Warehouse</InputLabel>
                        <Select
                            value={props.warehouse}
                            name='warehouse'   
                            label="Warehouse"        
                            onChange={(e) => props.setWarehouse(e.target.value)}
                            defaultValue={""}
                        >
                            <MenuItem value="KY">Kentucky</MenuItem>
                            <MenuItem value="PA">Pennsylvania</MenuItem>
                        </Select>
                    </FormControl>
                </Box>
            </Box>
            <Box className={props.isMobile ? "mobile-box" : "desktop-box"} display="flex" sx={{mt:"20px", ml:"375px"}}>
                <Button onClick={()=>props.clear()} variant="contained" color="info" size="large">Clear</Button>
                <Button onClick={()=>props.search()} variant="contained" color="success" size="large" sx={{ml:"40px"}}>Search</Button>
            </Box>
            
        </Box>
    )
}

export default EmployeeQueryForm;