import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import HoldTable from './HoldTable';
import orderHelpers from '../../helpers/orders';
import HoldOrderDetails from './HoldOrderDetails';

function HoldOrders(props) {
    
    const [refresh, setRefresh] = useState(false);
    const [ordersRaw, setOrdersRaw] = useState([]);
    const [orders, setOrders] = useState([])
    const [selected, setSelected] = useState([]);
    const [displayOrder, setDisplayOrder] = useState({});

    const setLoading = props.setLoading;
    const company = props.company;
    const token = props.token;

    
    const handleSelect = (e) => {
        setSelected(e)
        let row = orders.filter(obj => obj.id === e[0])[0]
        let order = ordersRaw.filter(obj => obj.internal_po_number === row.internal_po_number);
        let skus = [...new Set(order.map(obj => obj.internal_sku))];
        let items = [];
        let id = 1;
        for(let sku of skus) {
            let rows = order.filter(obj => obj.internal_sku === sku);
            let qty = rows.reduce((a, b) => a + b.quantity, 0);
            let price = rows.reduce((a, b) => a + b.external_price, 0);
            items.push({
                internal_sku: sku,
                quantity: qty,
                external_price: price.toFixed(2),
                unit_price: rows[0].external_price,
                id: id
            })
            id++;
        }
        order[0].items = items;
        order[0].price = row.total_price;
        order[0].pieces = row.pieces
        setDisplayOrder(order[0])
        console.log(order[0])
    }
    const handleClose = (e) => {
        setDisplayOrder({})
    }

    useEffect(() => {
        const getOrders = async() => {
            setLoading(true);
            let new_orders = await orderHelpers.getHoldOrders(company, token);
            console.log(new_orders);
            setOrdersRaw(new_orders);
            let out = [];
            let pos = [...new Set(new_orders.map(obj => obj.internal_po_number))];
            let id = 1;
            for(let po of pos) {
                let orders = new_orders.filter(obj => obj.internal_po_number === po);
                let qty = orders.reduce((a,b)=>a + b.quantity, 0);
                let price = orders.reduce((a,b)=>a + b.external_price, 0.0);
                out.push({
                    internal_po_number: po,
                    order_date: orders[0]?.order_date,
                    total_price: price.toFixed(2),
                    pieces: qty,
                    vendor_name: orders[0]?.vendor_name,
                    id: id
                })
                id++;
            }
            setOrders(out);
            setLoading(false);
        }
        getOrders();
    }, [refresh, company, token, setLoading])

        return(
            <Box className={props.isMobile ? "mobile-top" : "desktop-top"} sx = {{display: 'flex', flexDirection: 'column'}}>
                <Box className={props.isMobile ? "mobile-box" : "desktop-box"}>
                    <h1>Orders On Hold</h1> 
                    <Box className={props.isMobile ? "mobile-box" : "desktop-box"} display="flex" flexDirection="column">
                    {displayOrder ? 
                        <HoldOrderDetails {...props} order={displayOrder} setOpenDetails={handleClose} /> 
                        : null}
                        <Button style={{margin: "auto", marginTop:"20px"}} variant="contained" color="success" onClick={()=>setRefresh(!refresh)}>Refresh</Button>
                        <HoldTable 
                            isMobile={props.isMobile}
                            orders={orders}
                            handleSelect={handleSelect}
                            selected={selected}
                        />
                    </Box>                
                </Box>
            </Box>
        )

}

export default HoldOrders;