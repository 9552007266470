import Box from '@mui/material/Box';

import React, { useEffect, useState } from 'react';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import WarehouseTable from './WarehouseTable'

function ReviewTable(props) {
    const [kyOrder, setKYOrder] = useState([]);
    const [paOrder, setPAOrder] = useState([]);


    useEffect(() => {
        let ky = [];
        let pa = [];
        for(let item of props.order.items) {
            if(item.decision === "Kentucky") {
                item.fulfill_ky = item.quantity;
                item.fulfill_pa = 0;
            }
            else if(item.decision === "Pennsylvania") {
                item.fulfill_pa = item.quantity;
                item.fulfill_ky = 0;
            }
            if(item.fulfill_ky > 0) {
                let temp = JSON.parse(JSON.stringify(item));
                temp.quantity = item.fulfill_ky;
                ky.push(temp);
            }
            if(item.fulfill_pa > 0) {
                let temp = JSON.parse(JSON.stringify(item));
                temp.quantity = item.fulfill_pa;
                pa.push(temp);
            }
        }
        setKYOrder(ky);
        setPAOrder(pa);
    }, [props.order])

    return(
        <Box className={props.isMobile ? "mobile-box" : "desktop-box"} style={{width:"100%"}} display="flex">
            {kyOrder.length > 0 ? 
                <WarehouseTable wh="Kentucky" {...props} order={kyOrder} />
            : null}
            {kyOrder.length > 0 && paOrder.length > 0 ? 
                <Divider sx={{ml:"10px", mr:"10px"}} orientation="vertical" flexItem />
            : null}
            {paOrder.length > 0 ? 
                <WarehouseTable wh="Pennsylvania" {...props} order={paOrder} />
            : null}
            {kyOrder.length > 0 || paOrder.length > 0 ? 
                <Button size="large" style={{height:"fit-content", position:"fixed", bottom:"30px", right:"30px", width:"200px"}} variant="contained" color="success">Submit</Button>
            : null}
        </Box>   
    )


}

export default ReviewTable;