import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import warehouseHelpers from '../../helpers/warehouse';
import EfficiencyTable from './EfficiencyTable';

function EfficiencyReport(props) {

    const [report, setReport] = useState([])


    useEffect(() => {
        const getIds = async() => {
            props.setLoading(true);
            let res = await warehouseHelpers.getEfficiencyTime(props.company, props.token);
            console.log(res)
            props.setLoading(false);
        }
        getIds()
    }, [])

    


    return(
        <Box display="flex" flexDirection="column" className={props.isMobile ? "mobile-top" : "desktop-top"}>
            <Box className={props.isMobile ? "mobile-box" : "desktop-box"}>
                <h1>Efficiency Report</h1> 
                <EfficiencyTable {...props} report={report} />
            </Box>
        </Box>
    )
}

export default EfficiencyReport;