import React, { useEffect, useState } from 'react';
import Drawer from '@mui/material/Drawer'
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText'
import CircularProgress from '@mui/material/CircularProgress';
import Button from '@mui/material/Button';
import orderHelpers from '../../../helpers/orders';
import bulkHelpers from '../../../helpers/bulk';
import ProcessingPanel from './ProcessingPanel';

function ProcessingDrawer(props) {
    const [order, setOrder] = useState(null);
    const [progress, setProgress] = useState(true);
    const [default_wh, setDefaultWh] = useState("Kentucky");
    const [other_wh, setOtherWh] = useState("Pennsylvania");
    const [all_cancel, setAllCancel] = useState(false);
    const [needs_split, setNeedsSplit] = useState(false);



    useEffect(() => {
        const getOrder = async() => {
            let inv = await getItems();
            let body = {internal_po_number: props.order.internal_po_number, vendor_name: props.order.vendor_name}
            let res = await orderHelpers.getBulkOrders(props.company + "_bulk", body, props.token);
            if(!res || res.length === 0) {
                setOrder(null);
                return;
            }
            let out = res[0];
            out.items = [];
            let skus = [...new Set(res.map(obj => obj.internal_sku))];
            let i = 0;
            let total = 0;
            let pieces = 0;
            for(let sku of skus) {
                let items = res.filter(obj => obj.internal_sku === sku);
                let curr_inv = inv[sku];
                let count = items.reduce((a, b) => a + b.quantity, 0);
                let item = items[0];
                let decision = await getDefaultDecision(curr_inv, count)
                out.items.push({
                    id: i,
                    internal_sku: item.internal_sku,
                    external_sku: item.external_sku,
                    external_price: item.external_price,
                    internal_price: item.internal_price,
                    quantity: count,
                    kentucky: curr_inv.Kentucky,
                    pennsylvania: curr_inv.Pennsylvania,
                    decision: decision
                });
                pieces += count;
                total += item.internal_price || item.external_price;
                i++;
            }
            out.total_price = total;
            out.total_pieces = pieces;
            setOrder(out);
            if(out.items.every(obj => obj.decision === "Cancel")) setAllCancel(true);
            else setAllCancel(false);
            if(out.items.every(obj => obj.decision !== "Split" && obj.decision !== "Partial")) setNeedsSplit(false);
            else setNeedsSplit(true);
        }
        const getItems = async() => {
            let result = await bulkHelpers.getBulkInventory(props.company + "_bulk", props.token);
            let res = result.data;
            console.log(res);
            if(res.length === 0) {
                // setInv({})
                return {};
            }
            let out = {};
            for(let item of res) {
                out[item.sku] = item;
            }
            // setInv({out});
            return out;
        }
        const getDefaultDecision = async(inv, qty) => {
            let def = inv[default_wh];
            let oth = inv[other_wh];
            console.log(qty)
            if(def >= qty) {
                return default_wh;
            }
            if(oth >= qty) {
                return other_wh;
            }
            if(oth+def >= qty) {
                return "Split";
            }
            if(oth+def > 0) {
                return "Partial";
            }
            return "Cancel"
        }
        getOrder();
    }, [])
    
    const confirmClose = async() => {
        if(progress) {
            if(window.confirm("Close processing panel? This will lose any unsaved progress.")) {
                props.setProcessing(false)
            }
        }
        else {
            props.setProcessing(false)
        }
    }



    const rejectOrder = async() => {
        if(window.confirm("WARNING: Reject entire order?")) {
            // Reject the whole thing.
        }
    }

    return(
        <Drawer open={props.processing} onClose={() => confirmClose()} anchor="bottom">
            {order ? 
            <Box style={{height: "600px", width:"75%"}}  display="flex" sx={{m:"auto", mb:"80px", pt:"20px"}}>
                <List dense>
                    <ListItem>
                        <ListItemText primary="Order Number" primaryTypographyProps={{variant:"h4"}} secondaryTypographyProps={{variant:"h5"}} secondary={order.internal_po_number} />
                    </ListItem>
                    <ListItem>
                        <ListItemText primary="Order Date" secondary={order.order_date ? order.order_date.substring(0, 10) : "N/A"}/>
                    </ListItem>
                    <ListItem>
                        <ListItemText primary="Vendor" secondary={order.vendor_name} />
                    </ListItem>
                    <ListItem>
                        <ListItemText primary="Pieces" secondary={order.total_pieces} />
                    </ListItem>
                    <ListItem>
                        <ListItemText 
                        primaryTypographyProps={{ style: {color: order.total_price > 100 ? "green" : "red"} }} 
                        secondaryTypographyProps={{ style: {color: order.total_price > 100 ? "green" : "red"} }} 
                        primary="Total Price" 
                        secondary={`$${order.total_price}`} />
                    </ListItem>
                    <Button onClick={()=>rejectOrder()} style={{marginTop:"20px"}} variant="contained" color="error">Reject Order</Button>
                </List>
                <Divider sx={{ml:"10px", mr:"10px"}} orientation="vertical" flexItem />
                <ProcessingPanel {...props} order={order} setOrder={setOrder} all_cancel={all_cancel} setAllCancel={setAllCancel} needs_split={needs_split} setNeedsSplit={setNeedsSplit} />

            </Box> :
            <Box display="flex" style={{height:"100px"}}>
                <CircularProgress style={{margin:"auto"}} />
            </Box>}
        </Drawer>
    )
}

export default ProcessingDrawer;