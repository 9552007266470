import {React, useState, useEffect}from 'react';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText'
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import DetailsItems from './DetailsItems';
import Alert from '@mui/material/Alert';
import orderHelpers from '../../helpers/orders';
import fileHelpers from '../../helpers/files';

function DetailsPackage(props) {

    const shipment = props.shipment || {items: []};
    
    const [status, setStatus] = useState({});
    const [tStatus, setTStatus] = useState({});
    const [reshipSuccess, setReshipSuccess] = useState("");


    useEffect(() => {
        const daysInMs = 10 * 24 * 60 * 60 * 1000;
        const cutOff = new Date().getTime() - daysInMs;
        const date = new Date(shipment.date).getTime();
        setStatus(shipment.acumatica_status === "acumatica_processing" ? {name: "Processing", class: "created"} :
        shipment.acumatica_status === "acumatica_order_created" ? {name: "Order Created", class: "staged"} :
        shipment.acumatica_status === "acumatica_shipment_created" ? {name: "Shipment Created", class: "shipped"} :
        shipment.acumatica_status === "acumatica_order_failed" ? {name: "Order Failed", class: "error"} :
        shipment.acumatica_status === "acumatica_shipment_failed" ? {name: "Shipment Failed", class: "error"} : {});
        setTStatus(shipment.tracking_status === "tracking_created" ? date < cutOff ? {name: "Label Created - Late", class: "error", reship: true} : {name:"Label Created", class:"created"} :
        shipment.tracking_status === "tracking_confirmed" ? {name:"Shipped", class:"shipped"} : shipment.tracking_status === "tracking_reshipped" ? {name:"Reshipped", class:"staged"} : {})
    }, [shipment.acumatica_status, shipment.date, shipment.tracking_status])

    const downloadLabel = async(ship) => {
        props.setLoading(true);
        let po = props.internal_po_number;
        let tn = ship.tracking_number;
        let payload = {internal_po_number: po, tracking_number: tn};
        let urls = await orderHelpers.getPackageLabelDownloadLinks(props.company, payload, props.token);
        await fileHelpers.downloadFile(urls.ps, `${po}_${tn}_packing_slip`);
        await fileHelpers.downloadFile(urls.sl, `${po}_${tn}_shipping_label`);
        props.setLoading(false);
    }

    const reship = async(ship) => {
        if(!window.confirm(`Reship the package with tracking number ${ship.tracking_number}?`)) return;
        props.setLoading(true);
        setReshipSuccess("");
        let tn = ship.tracking_number;
        let payload = {tracking_number: tn};
        let res = await orderHelpers.reshipPackage(props.company, payload, props.token);
        setReshipSuccess(res.success ? "success" : "error");
        if(res.success) setTStatus({name:"Reshipped", class: "staged"});
        props.setLoading(false);
    }

    return (
            <Box display="flex" flexDirection="column" style={{height: "500px", marginTop:"20px"}}>
                <Typography variant="h4" align="center">Package {props.packageNum + 1}</Typography>
                <Box display="flex" flexDirection="column">
                    <Box display="flex">
                        <List dense>
                            <ListItem>
                                <ListItemText primary="Carrier" secondary={shipment.carrier} />
                            </ListItem>
                            <ListItem>
                                <ListItemText primary="Method" secondary={shipment.method} />
                            </ListItem>
                            <ListItem>
                            </ListItem>
                            <ListItem>
                                <ListItemText primary="Tracking Number" secondary={shipment.tracking_number} />
                            </ListItem>
 
                        </List>
                        <List dense>
                            <ListItem>
                                <ListItemText primary="Acumatica Shipment" secondary={shipment.acu_ship_num || "N/A"}/>
                            </ListItem>
                            <ListItem>
                                <ListItemText disableTypography 
                                    primary={<Typography variant="body2">Acumatica Status</Typography>}
                                    secondary={<Typography variant="body2" className={status.class}>{status.name}</Typography>}
                                />
                            </ListItem>
                            {shipment.truck_number ? <ListItem>
                                <ListItemText primary="Truck Number" secondary={shipment.truck_number}/>
                            </ListItem> : null}
                            <ListItem>
                                <ListItemText disableTypography 
                                    primary={<Typography variant="body2">Status</Typography>}
                                    secondary={<Typography variant="body2" className={tStatus.class}>{tStatus.name}</Typography>}
                                />
                            </ListItem>
                        </List>
                        <List dense>
                            <ListItem>
                                <ListItemText primary="Warehouse" secondary={shipment.warehouse} />
                            </ListItem>
                            <ListItem>
                                <ListItemText primary="Pull Sheet" secondary={shipment.pullsheet_id || "None"} />
                            </ListItem>
                            <ListItem>
                                <ListItemText primary="Total Quantity" secondary={shipment.total_on_shipment}/>
                            </ListItem>
                        </List>
                        <Box sx={{m:"auto"}} display="flex" flexDirection="column">
                            <Button onClick={()=>downloadLabel(shipment)} sx={{mb:"20px", color:"white"}} className={`${props.warehouse} ${props.company} primary`}>Download Label</Button>
                            {tStatus.reship && props.status.name !== "Order Cancelled" ? <Button disabled={props.isLoading} onClick={()=>reship(shipment)} sx={{mt:"20px"}} variant="contained" className={`${props.warehouse} ${props.company} tertiary`}>Reship</Button> : null}
                            {reshipSuccess === "success" || reshipSuccess === "error" ? <Alert severity={reshipSuccess}>{reshipSuccess === "success" ? "Package Reset" : "Failed to Reset Package"}</Alert> : null}
                        </Box>
                    </Box>
                    
                </Box>
                
                <Divider flexItem />
                <Box display="flex" flexDirection="column" style={{overflowY:"scroll", height:"60%"}}>
                    <DetailsItems items={shipment.items} />
                </Box>
            </Box>
    )
}

export default DetailsPackage;