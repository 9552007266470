import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import Box from '@mui/material/Box';
import {lighten } from '@mui/material/styles';

const columns = [ 
    {
        field: 'id',
        headerName: "ID",
        flex: 1,
        editable: false,
        minWidth: 100
    },
    {
        field: 'worker_name',
        headerName: 'Name',
        flex: 1,
        editable: false,
        minWidth: 200
    }, 
    {
        field: "warehouse",
        headerName: "Warehouse",
        flex: 1,
        editable: false,
        minWidth: 100
    },
    {
        field: 'group',
        headerName: 'Group',
        flex: 1,
        editable: false,
        minWidth: 50
    },
    {
        field: 'worked_time',
        headerName: "Worked Time (Hours)",
        editable: false,
        minWidth: 200,
    },
    {
        field: 'break_time',
        headerName: "Break Time (Hours)",
        editable: false,
        minWidth: 200
    }

]


function EmployeeTimeTable(props) {

    return(
        <Box className={props.isMobile ? "mobile-table-box" : "desktop-table-box"} style={{width:"70%"}}>
            <DataGrid
                loading={props.loading}
                slots={{ toolbar: GridToolbar }} 
                style={{width:"100%"}}
                rows={props.times}
                className={props.isMobile ? "mobile-table" : "desktop-table"}
                columns={columns}
                rowsPerPageOption = {[25, 50, 100]}
                checkboxSelection = {false}
                sortModel = {[{field:'id', sort: 'asc'}]}
            />
        </Box>   
    )


}

export default EmployeeTimeTable;