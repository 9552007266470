import {PDFDocument} from 'pdf-lib';


const mergeFilesByUrl = async(bytes_array, pdf) => {
    try {
        for(let bytes of bytes_array) {
            let doc = await PDFDocument.load(bytes);
            for(let i = 0; i < doc.getPageCount(); i++) {
                let [page] = await pdf.copyPages(doc, [i]);
                pdf.addPage(page);
            }
        }
        return pdf;
    }
    catch(err) {
        console.log(`mergeFilesByUrl error: ${err}`);
        return pdf;
    }
}

const getBytesFromUrls = async(urls, page_types) => {
    let out = [];
    for(let order of urls) {
        for(let page_type of page_types) {
            let url = order[page_type];
            if(!url) {
                console.log(`No URL found: ${page_type} | ${order}`);
                continue;
            }
            try {
                out.push(fetch(url));
            }
            catch(err) {
                console.log(`Failed to add ${page_type} for ${url}`)
            }
        }
    }
    let raw = await Promise.all(out)
    let bytes_raw = raw.map(obj => {if(obj) {return obj.arrayBuffer()}});
    let bytes = await Promise.all(bytes_raw);
    return bytes;
}

const mergeSortedPDFs = async(urls, page_types, pdf) => {
    let bytes_array = await getBytesFromUrls(urls, page_types);
    pdf = await mergeFilesByUrl(bytes_array, pdf)
    return pdf;
}

const fileHelpers = {

    mergeUnsortedPDFs: async(urls, page_types=["master"]) => {
        let pdf = await PDFDocument.create();
        pdf = await mergeSortedPDFs(urls, page_types, pdf);
        let uri = await pdf.saveAsBase64({dataUri: true});
        return uri.substring(uri);
    },


    mergePDFs: async(urls, page_types=["tracking","packing"]) => {
        let now = new Date();
        let pdf = await PDFDocument.create();
        for(let url_sorted_obj of urls.sorting) {
            let url_sku = url_sorted_obj.sku;
            let url_obj = urls[url_sku];
            pdf = await mergeSortedPDFs(url_obj, page_types, pdf);
        }
        // console.log(`Fetching took ${out.bytes} seconds.`)
        // console.log(`Merging took ${out.merge} milliseconds.`)
        // console.log(`Total Process Took ${Math.round((new Date() - now) / 1000)} seconds.`)
        let uri = await pdf.saveAsBase64({dataUri: true});
        return uri.substring(uri);
    },

    downloadFile: (fileLink, fileName) => {
        let a = document.createElement('a');
        a.href = fileLink;
        a.target="_blank";
        a.setAttribute('download', fileName);
        a.click();
    }
}

export default fileHelpers