import Box from '@mui/material/Box';

import React, { useEffect, useState } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';


function WarehouseTable(props) {

    return(
        <Box className={props.isMobile ? "mobile-table-box" : "desktop-table-box"} style={{width:"100%"}}>
            <h2>{props.wh}:</h2>
            <TableContainer className={props.isMobile ? "mobile-table-box" : "desktop-table-box"}>
                <Table className={props.isMobile ? "mobile-table" : "desktop-table"}  sx={{zIndex:0}}>
                    <TableHead>
                        <TableRow>
                            <TableCell align="center" style={{fontWeight: "bold"}}>SKU</TableCell>
                            <TableCell align="center" style={{fontWeight: "bold"}}>Quantity</TableCell>
                            <TableCell align="center" style={{fontWeight: "bold"}}>Price</TableCell>
                        </TableRow>
                    </TableHead>
                        <TableBody>
                            {props.order.map((row, index) => {
                                return(
                                    <TableRow key={index} tabIndex={-1}>
                                        <TableCell align="center">{row.internal_sku}</TableCell>
                                        <TableCell align="center">{row.quantity}</TableCell>
                                        <TableCell align="center">${parseFloat(row.external_price * row.quantity).toFixed(2)}</TableCell>
                                    </TableRow> 
                                )
                            })}
                        </TableBody>
                </Table>
            </TableContainer>
        </Box>    
    )


}

export default WarehouseTable;