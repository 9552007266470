import React, { Component } from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { DataGrid } from '@mui/x-data-grid';
import './styles.css'

const columns = [
    {
        field: 'pullsheet_id',
        headerName: 'ID',
        flex: 1,
        minWidth: 70,
        editable: false,
    }, 
    {
        field: 'date',
        headerName: 'Date',
        flex: 1,
        minWidth: 100,
        editable: false
    },
    {
        field: 'wh_name',
        headerName: "Warehouse",
        flex: 1,
        minWidth: 70,
        editable: false
    },  
    {
        field: 'carrier',
        headerName: 'Carrier',
        flex: 1,
        valueGetter: (params) => {
            if(params.row.carrier.toLowerCase() === "fedex") return "FedEx";
            if(params.row.carrier.toLowerCase() === "usps") return "USPS";
            if(params.row.carrier.toLowerCase() === "ups") return "UPS";
        },
        minWidth: 100,
        editable: false
    },  
    {
        field: 'order_count',
        headerName: 'Orders',
        flex: 1,
        minWidth: 70,
        editable: false
    },  
    {
        field: 'piece_count',
        headerName: 'Pieces',
        flex: 1,
        minWidth: 70,
        editable: false
    },  
    {
        field: "type",
        headerName: 'Type',
        valueGetter: (params) => {
            console.log(params.row.type)
            return params.row.type.replace("_", " ");
        },
        flex: 1,        
        minWidth: 70,
        editable: false
    }, 
    {
        field: 'status',
        headerName: 'Status',
        flex: 1,
        minWidth: 100,
        editable: false,
        valueGetter: (params) => {
            if(params.row.status === "order_created") return "Unbuilt";
            if(params.row.status === "order_pulled") return "Pulled";
            if(params.row.status === "order_built") return "Built";
            if(params.row.status === "order_staged") return "Staged";
            if(params.row.status === "order_shipped") return "Shipped";
        },
    }
]

class PullsheetsTable extends Component {

    render(){
        return(
            <Box display="flex" flexDirection="column" className={this.props.isMobile ? "mobile-box" : "desktop-box"}>
                
                <Box>
                    <Button variant="contained" onClick={() => this.props.refresh()} color="primary">Refresh</Button>
                    <Button variant='contained' onClick={this.props.showPullsheetConfig} sx={{ml: "10px"}} color="success">New Pullsheet</Button>
                    <Box>
                    {
                        this.props.selected.length === 1 ?
                        <Box sx={{mt:"10px", mb:"10px"}}> 
                            <Button variant='contained' onClick={this.props.downloadPullsheet} className={`${this.props.warehouse} ${this.props.company} primary`}>Download Pullsheet</Button>
                            <Button variant='contained' onClick={this.props.showOrders} color="success" sx={{ml:"10px"}}>View Orders</Button>
                            <Button variant='contained' sx={{ml:"10px"}} onClick={this.props.getShippingDocs} color="success">Get Shipping Docs</Button>
                            <Button variant='contained' sx={{ml:"10px"}} onClick={this.props.getCarrierReport} color="success">Get Carrier Report</Button>
                            <Button variant="contained" sx={{ml:"10px"}} onClick={this.props.regenPackslips} color="secondary">Regenerate Packslips</Button>
                        </Box>
                        : null
                        
                    }    
                </Box>
                    <Box className={this.props.isMobile ? "mobile-table-box" : "desktop-table-box"}>
                        <DataGrid
                        className={this.props.isMobile ? "mobile-table" : "desktop-table"}
                        rows={this.props.pullSheets.filter((obj) => {
                            if(this.props.warehouse === "All") return obj;
                            if(obj.wh_name && obj.wh_name.toLowerCase() === this.props.warehouse) return obj;
                            return null;
                        })}
                        columns={columns}
                        rowsPerPageOption = {[25, 50, 100]}
                        checkboxSelection = {true}
                        onSelectionModelChange = {(selectionModel, details) => {
                            this.props.updateSelected(selectionModel)
                        }}
                        selectionModel={this.props.selected}
                        sortModel = {[{field: 'pullsheet_id', sort: 'desc'}]}
                        getRowClassName = {(params) => `pullsheet-table-row-warehouse-${params.row.warehouse}`}
                        loading={this.props.loading}
                        />
                    </Box>
                </Box>
            </Box>
        )
    }
}

export default PullsheetsTable;