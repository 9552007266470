import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Tabs from '@mui/material/Tabs'
import Tab from '@mui/material/Tab';
import BulkOrderTableTemplate from './BulkOrderTableTemplate';
import ProcessingDrawer from './Processing/ProcessingDrawer';

function BulkOrders(props) {
    const [value, setValue] = useState(0);
    const [selectedOrder, setSelectedOrder] = useState([]);
    const [processing, setProcessing] = useState(false);

    const processOrder = async() => {
        setProcessing(true);
    }

    const tables = {
        "New": {
            buttons: [
                {
                    type: "primary",
                    function: processOrder,
                    text: "Process Order"
                }
            ],
        },
        "Not Routed": {
            buttons: [
                {
                    type: "primary",
                    function: null,
                    text: "Route Order"
                },
                {
                    type: "secondary",
                    function: null,
                    text: "Add Shipment Information"
                }
            ],
        },
        "Not Built": {
            buttons: [
                {
                    type: "primary",
                    function: null,
                    text: "Get Pullsheets"
                },
                {
                    type: "secondary",
                    function: null,
                    text: "Get Labels"
                }
            ],
        },
        "Ready": {
            buttons: [
                {
                    type: "primary",
                    function: null,
                    text: "Export Order"
                }
            ],

        },
        "Shipped": {
            buttons: [
                {
                    type: "primary",
                    function: null,
                    text: "Export Order"
                }
            ],
        },
        "Invoiced": {
            buttons: [
                {
                    type: "primary",
                    function: null,
                    text: "Export Order"
                },
                {
                    type: "secondary",
                    function: null,
                    text: "Mark Complete"
                }
            ],
        },
        "Complete": {
            buttons: [
                {
                    type: "primary",
                    function: null,
                    text: "Export Order"
                }
            ],
            status: "Complete",
        }
    }
    
    let headers = Object.keys(tables);



    const handleChange = async(event, newValue) => {
        setValue(newValue);
    }
    return(
        <Box display="flex" flexDirection="column" className={props.isMobile ? "mobile-top" : "desktop-top"}>
            {processing ? <ProcessingDrawer {...props} order={selectedOrder[0]} processing={processing} setProcessing={setProcessing} /> : null}
            <Box className={props.isMobile ? "mobile-box" : "desktop-box"}>
                <h1>Bulk Orders</h1> 
                <Tabs value={value} onChange={handleChange}>
                    {headers.map((key, index) => {
                        if(key === headers[headers.length-1]) return (
                            <Tab key={index} align="center" label={key} />
                        )
                        return(
                            <Tab key={index} align="center" label={key} sx={{borderRight: 1}} />
                        )
                    })}
                </Tabs>
                {headers.map((header,index) => {
                    let obj = tables[header];
                    return(
                        value === index ?
                        <BulkOrderTableTemplate selectedRows={selectedOrder} setSelectedRows={setSelectedOrder} key={index} buttons={obj.buttons} status={header} {...props} />
                        : null
                    )
                })}
            </Box>
        </Box>
    )
}

export default BulkOrders;